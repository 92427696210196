.nav-item {
  font-size: 1rem;
  font-weight: 450;
  line-height: 1.5;
  padding: 8px 8px;
}
.avanio-logo {
  height: 60px;
  width: 180px;
}

.notification-div {
  z-index: 13;
}

html{
  margin-bottom: 70px;
}